<template>
  <div class="Awarp">
    <dashCard
      class="chuneng6"
      :operateWidth="operateWidth"
      :isLoading="isLoading"
      :gridOption="gridOption"
      @changeScreen="changeScreen"
    >
      <template slot="title">{{commonLangHandler('chuneng6_runCurvePCS','储能PCS运行曲线', getZEdata)}}</template>
      <template slot="aside">
        <div class="type-group">
          <span
            class="pick-type"
            v-for="(item, idx) in timetType"
            :key="idx"
            @click="changeType(item.code)"
          >
            <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
              item.name
            }}</font>
          </span>
        </div>
        <a-date-picker
          v-if="searchObj.TYPE == '按天'"
          placeholder="commonLangHandler('chuneng7_day','选天', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
       
        <a-month-picker
          v-if="searchObj.TYPE == '按月'"
          placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-date-picker
          v-if="searchObj.TYPE == '按年'"
          placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
          mode="year"
          :open="panelOpen"
          @openChange="openChange"
          @panelChange="panelChange"
          format="YYYY"
        />
      </template>
      <div class="card-content1">
        <a-select
          v-model="searchObj['ITEM']"
          style="width: 300px; margin-right: 16px"
          mode="multiple"
          @change="handleChange(1)"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="(item, key) in searchObj.itemList"
            :key="key"
            :value="item.assetNumber"
            :disabled="
              searchObj['ITEM'].length >= 2 &&
              searchObj['ITEM'].findIndex(
                (value) => value === item.assetNumber
              ) === -1
            "
          >
            {{ item.assetName }}
          </a-select-option>
        </a-select>
        <a-select
          v-model="searchObj['ITEM2']"
          style="width: 140px; margin-right: 16px"
          @change="handleChange(2,true)"
          :disabled="searchObj.ITEM.length === 0"
          allowClear
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="(item, key) in itemType1"
            :key="key"
            :value="item.code"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-select
          v-model="searchObj['ITEM3']"
          style="width: 240px"
          @change="handleChange(3,true)"
          mode="multiple"
          v-if="
            searchObj.ITEM.length === 1 &&
            searchObj.ITEM2 !== '4' &&
            searchObj.ITEM2 !== '5'
          "
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="(item, key) in itemList3"
            :key="key"
            :value="item.code"
            :disabled="
              searchObj['ITEM3'].length >= 2 &&
              searchObj['ITEM3'].findIndex((value) => value === item.code) ===
                -1
            "
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-select
          v-model="searchObj['ITEM3']"
          style="width: 240px"
          @change="handleChange(3,true)"
          mode="multiple"
          v-if="
            searchObj.ITEM.length === 2 &&
            searchObj.ITEM2 !== '4' &&
            searchObj.ITEM2 !== '5'
          "
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="(item, key) in itemList3"
            :key="key"
            :value="item.code"
            :disabled="
              searchObj['ITEM3'].length >= 1 &&
              searchObj['ITEM3'].findIndex((value) => value === item.code) ===
                -1
            "
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="card-content1">
        <span
          style="width: 300px; margin-right: 16px; display: inline-block"
        ></span>
        <a-select
          v-model="searchObj['ITEM4']"
          style="width: 140px; margin-right: 16px"
          @change="handleChange(4,true)"
          :disabled="searchObj.ITEM.length === 0"
          allowClear
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="(item, key) in itemType2"
            :key="key"
            :value="item.code"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-select
          v-model="searchObj['ITEM5']"
          style="width: 240px"
          @change="handleChange(5,true)"
          mode="multiple"
          v-if="
            searchObj.ITEM.length === 1 &&
            searchObj.ITEM4 !== '4' &&
            searchObj.ITEM4 !== '5'
          "
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="(item, key) in itemList5"
            :key="key"
            :value="item.code"
            :disabled="
              searchObj['ITEM5'].length >= 2 &&
              searchObj['ITEM5'].findIndex((value) => value === item.code) ===
                -1
            "
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-select
          v-model="searchObj['ITEM5']"
          style="width: 240px"
          @change="handleChange(5,true)"
          mode="multiple"
          v-if="
            searchObj.ITEM.length === 2 &&
            searchObj.ITEM4 !== '4' &&
            searchObj.ITEM4 !== '5'
          "
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="(item, key) in itemList5"
            :key="key"
            :value="item.code"
            :disabled="
              searchObj['ITEM5'].length >= 1 &&
              searchObj['ITEM5'].findIndex((value) => value === item.code) ===
                -1
            "
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="card-content2">
        <chartBoard ref="chart1" :option="chartOption1" />
      </div>
    </dashCard>
    <a-modal
      v-if="!isModal"
      class="Amodal"
      v-model="option.visible"
      :closable="false"
      :footer="null"
      :keyboard="false"
      width="100%"
      :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <chuneng6
          :option="option"
          :isModal="true"
          :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "chuneng6",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      dwList: ["", ""],
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中

      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
       
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      itemList: [],
      itemList2: [
        {
          code: "1",
          name: "电流",
        },
        {
          code: "2",
          name: "电压",
        },
        {
          code: "3",
          name: "功率",
        },
        {
          code: "4",
          name: "功率因数",
        },
        {
          code: "5",
          name: "设备温度",
        },
      ],
      panelOpen: false,
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
        ITEM: [], //选中的项目
        ITEM2: "", //选中的项目
        ITEM3: [], //选中的项目
        ITEM4: "", //选中的项目
        ITEM5: [], //选中的项目
        itemList: [],
        gridLeft: [],
        gridright: [],
        dwList: ["", ""],
      },
      chartOption1: {},
      detailInfo: {
        chart1: {},
      },
      colorList: [
        "#7CB5EC",
        "#444449",
        "#90ED7D",
        "#B0E2FF",
        "#696969",
        "#98FB98",
      ],
    };
  },
  computed: {
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
    itemType1() {
      let arr = [];
      this.itemList2.forEach((obj) => {
        if (obj.code !== this.searchObj.ITEM4) {
          arr.push(obj);
        }
      });
      return arr;
    },
    itemType2() {
      let arr = [];
      this.itemList2.forEach((obj) => {
        if (obj.code !== this.searchObj.ITEM2) {
          arr.push(obj);
        }
      });
      return arr;
    },
    itemList3() {
      let lang = sessionStorage.getItem("locale") || 'CH'
      if (this.searchObj.ITEM2 === "1") {
        if(lang=='EN'){
            return[
              { name: "Phase A", code: "essPCSAcCurrentIL1" },
              { name: "Phase B", code: "essPCSAcCurrentIL2" },
              { name: "Phase C", code: "essPCSAcCurrentIL3" },
              { name: "direct-current", code: "essPCSDcCurrent" },
            ]
          }else{
            return [
              { name: "A相", code: "essPCSAcCurrentIL1" },
              { name: "B相", code: "essPCSAcCurrentIL2" },
              { name: "C相", code: "essPCSAcCurrentIL3" },
              { name: "直流", code: "essPCSDcCurrent" },
            ];
          }
      } else if (this.searchObj.ITEM2 === "2") {
        if(lang=='EN'){
            return[
              { name: "Phase A", code: "essPCSAcVoltagesU1" },
              { name: "Phase B", code: "essPCSAcVoltagesU2" },
              { name: "Phase C", code: "essPCSAcVoltagesU3" },
              { name: "direct-current", code: "essPCSDcVoltages" },
            ]
          }else{
            return [
              { name: "A相", code: "essPCSAcVoltagesU1" },
              { name: "B相", code: "essPCSAcVoltagesU2" },
              { name: "C相", code: "essPCSAcVoltagesU3" },
              { name: "直流", code: "essPCSDcVoltages" },
            ];
          }
      } else if (this.searchObj.ITEM2 === "3") {
        if(lang=='EN'){
            return[
              { name: "active", code: "essPCSTotalActivePower" },
              { name: "reactive", code: "essPCSTotalReactivePower" },
              { name: "direct-current", code: "essPCSDcTotalPower" },
            ]
          }else{
            return [
              { name: "有功", code: "essPCSTotalActivePower" },
              { name: "无功", code: "essPCSTotalReactivePower" },
              { name: "直流", code: "essPCSDcTotalPower" },
            ];
          }
      }
    },
    itemList5() {
      let lang = sessionStorage.getItem("locale") || 'CH'
      if (this.searchObj.ITEM4 === "1") {
        if(lang=='EN'){
            return[
              { name: "Phase A", code: "essPCSAcCurrentIL1" },
              { name: "Phase B", code: "essPCSAcCurrentIL2" },
              { name: "Phase C", code: "essPCSAcCurrentIL3" },
              { name: "direct-current", code: "essPCSDcCurrent" },
            ]
          }else{
            return [
              { name: "A相", code: "essPCSAcCurrentIL1" },
              { name: "B相", code: "essPCSAcCurrentIL2" },
              { name: "C相", code: "essPCSAcCurrentIL3" },
              { name: "直流", code: "essPCSDcCurrent" },
            ];
          }
      } else if (this.searchObj.ITEM4 === "2") {
        if(lang=='EN'){
            return[
              { name: "Phase A", code: "essPCSAcVoltagesU1" },
              { name: "Phase B", code: "essPCSAcVoltagesU2" },
              { name: "Phase C", code: "essPCSAcVoltagesU3" },
              { name: "direct-current", code: "essPCSDcVoltages" },
            ]
          }else{
            return [
              { name: "A相", code: "essPCSAcVoltagesU1" },
              { name: "B相", code: "essPCSAcVoltagesU2" },
              { name: "C相", code: "essPCSAcVoltagesU3" },
              { name: "直流", code: "essPCSDcVoltages" },
            ];
          }
      } else if (this.searchObj.ITEM4 === "3") {
        if(lang=='EN'){
            return[
              { name: "active", code: "essPCSTotalActivePower" },
              { name: "reactive", code: "essPCSTotalReactivePower" },
              { name: "direct-current", code: "essPCSDcTotalPower" },
            ]
          }else{
            return [
              { name: "有功", code: "essPCSTotalActivePower" },
              { name: "无功", code: "essPCSTotalReactivePower" },
              { name: "直流", code: "essPCSDcTotalPower" },
            ];
          }
      }
    },
  },
  methods: {
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          queryType: "day15m",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          queryType: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          queryType: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          queryType: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    getList() {
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/equipment/assetlist",
        method: "post",
        data: {
          deptCode: this.currentSelectDeptInfo.CODE,
          type: "储能PCS",
        },
      }).then((res) => {
        if(!res.data.data){
          this.itemList = []
        }else{
        this.itemList = res.data.data;
        this.searchObj.itemList = res.data.data;
        }
        if (this.searchObj.itemList.length > 0) {
          this.searchObj.ITEM.push(this.searchObj.itemList[0].assetNumber);
          this.handleChange(1)
        }
        if (this.itemType1.length > 0) {
          this.searchObj.ITEM2=this.itemType1[0].code;
          this.handleChange(2)
        }
        if (this.itemList3.length > 0) {
          this.searchObj.ITEM3.push(this.itemList3[0].code);
          this.handleChange(3)
        }
        if (this.itemType2.length > 0) {
          this.searchObj.ITEM4=this.itemType2[0].code;
          //console.log(this.searchObj.ITEM4);
          this.handleChange(4)
        }
        if (this.itemList5.length > 0) {
          this.searchObj.ITEM5.push(this.itemList5[0].code);
          this.handleChange(5)
        }

        this.getData()
      });
    },
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    onChange(value) {
      //console.log("onChange", value, this.searchObj);
      this.getData();
      this.$forceUpdate();
    },
    // 切换项目
    handleChange(value,reload) {
      if (value == "1") {
        if (this.searchObj.ITEM.length === 0) {
          this.searchObj.ITEM2 = "";
          this.searchObj.ITEM4 = "";
          this.searchObj.ITEM3 = [];
          this.searchObj.ITEM5 = [];
          this.dwList = ["", ""];
          this.searchObj.dwList = ["", ""];
          // 清图
          this.isLoading = true;
          this.detailInfo = {};
          var chart1 = {
            categoryData: [],
            seriesData: [],
          };
          this.detailInfo.chart1 = chart1;
          this.initEchart();
          this.isLoading = false;
          return;
        }
        if (this.searchObj.ITEM.length === 2) {
          if (this.searchObj.ITEM2 !== "4" && this.searchObj.ITEM2 !== "5") {
            this.searchObj.ITEM3 = [];
          }
          if (this.searchObj.ITEM4 !== "4" && this.searchObj.ITEM4 !== "5") {
            this.searchObj.ITEM5 = [];
          }
          // 清图
          this.isLoading = true;
          this.detailInfo = {};
          var chart1 = {
            categoryData: [],
            seriesData: [],
          };
          this.detailInfo.chart1 = chart1;
          this.initEchart();
          this.isLoading = false;
        }

        if (
          this.searchObj.ITEM3.length !== 0 ||
          this.searchObj.ITEM5.length !== 0
        ) {
          //this.getData();
        //return;
        }
      }
      if (value == "2") {
        //console.log(this.searchObj);
        this.searchObj.ITEM3 = [];
        if (this.searchObj.ITEM2 === "1") {
          this.dwList[0] = this.LangHandler('lv17current' , '电流(A)'); 
          this.searchObj.dwList[0] = this.LangHandler('lv17current' , '电流(A)');
        }
        if (this.searchObj.ITEM2 === "2") {
          this.dwList[0] = this.LangHandler('lv17vol' , '电压(V)');
          this.searchObj.dwList[0] = this.LangHandler('lv17vol' , '电压(V)');
        }
        if (this.searchObj.ITEM2 === "3") {
          this.dwList[0] = this.commonLangHandler('chuneng6_power','功率(KW)', this.getZEdata);
          this.searchObj.dwList[0] = this.commonLangHandler('chuneng6_power','功率(KW)', this.getZEdata);
        }
        if (this.searchObj.ITEM2 === "4") {
          this.searchObj.ITEM3 = ["essPCSTotalPowerFactor"];
          this.dwList[0] = "";
          this.searchObj.dwList[0] = "";
          //this.getData();
         // return;
        }
        if (this.searchObj.ITEM2 === "5") {
          this.searchObj.ITEM3 = ["essPCSDeviceTemperature"];
          this.dwList[0] = this.commonLangHandler('chuneng6_temp','温度(℃)', this.getZEdata);
          this.searchObj.dwList[0] = this.commonLangHandler('chuneng6_temp','温度(℃)', this.getZEdata);
          //this.getData();
          //return;
        }
        if (this.searchObj.ITEM2 === "6") {
          // this.dwList[0] = "A"
        }
        if (!this.searchObj.ITEM2) {
          this.dwList[0] = "";
          this.searchObj.dwList[0] = "";
          this.searchObj.ITEM3 = [];
          //this.getData();
         // return;
        }
        if (this.searchObj.ITEM5.length === 0) {
          // 清图
          this.isLoading = true;
          this.detailInfo = {};
          var chart1 = {
            categoryData: [],
            seriesData: [],
          };
          this.detailInfo.chart1 = chart1;
          this.initEchart();
          this.isLoading = false;
          return;
        } else {
         // this.getData();
          return;
        }
      }
      if (value == "3") {
        if (
          this.searchObj.ITEM3.length === 0 &&
          this.searchObj.ITEM5.length === 0
        ) {
          this.isLoading = true;
          this.detailInfo = {};
          var chart1 = {
            categoryData: [],
            seriesData: [],
          };
          this.detailInfo.chart1 = chart1;
          this.initEchart();
          this.isLoading = false;
        } else {
         // this.getData();
        }
      }
      if (value == "4") {
        //console.log(this.searchObj);
        this.searchObj.ITEM5 = [];
        if (this.searchObj.ITEM4 === "1") {
          this.dwList[1] = this.LangHandler('lv17current' , '电流(A)');
          this.searchObj.dwList[1] = this.LangHandler('lv17current' , '电流(A)');
         // this.getData()
        }
        if (this.searchObj.ITEM4 === "2") {
          this.dwList[1] = this.LangHandler('lv17vol' , '电压(V)');
          this.searchObj.dwList[1] = this.LangHandler('lv17vol' , '电压(V)');
         // this.getData()
        }
        if (this.searchObj.ITEM4 === "3") {
          this.dwList[1] = this.commonLangHandler('chuneng6_power','功率(KW)', this.getZEdata);
          this.searchObj.dwList[1] = this.commonLangHandler('chuneng6_power','功率(KW)', this.getZEdata);
         // this.getData()
        }
        if (this.searchObj.ITEM4 === "4") {
          this.searchObj.ITEM5 = ["essPCSTotalPowerFactor"];
          this.dwList[1] = "";
          this.searchObj.dwList[1] = "";
         // this.getData();
          return;
        }
        if (this.searchObj.ITEM4 === "5") {
          this.searchObj.ITEM5 = ["essPCSDeviceTemperature"];
          this.dwList[1] = this.commonLangHandler('chuneng6_temp','温度(℃)', this.getZEdata);
          this.searchObj.dwList[1] = this.commonLangHandler('chuneng6_temp','温度(℃)', this.getZEdata);
          //this.getData();
          //return;
        }
        if (this.searchObj.ITEM4 === "6") {
        }
        if (!this.searchObj.ITEM4) {
          //console.log("xxx");
          this.dwList[1] = "";
          this.searchObj.dwList[1] = "";
          this.searchObj.ITEM5 = [];
         // this.getData();
          return;
        }
        if (this.searchObj.ITEM3.length === 0) {
          // 清图
          this.isLoading = true;
          this.detailInfo = {};
          var chart1 = {
            categoryData: [],
            seriesData: [],
          };
          this.detailInfo.chart1 = chart1;
          this.initEchart();
          this.isLoading = false;
          return;
        } else {
          //this.getData();
          //return;
        }
      }
      if (value == "5") {
        if (
          this.searchObj.ITEM3.length === 0 &&
          this.searchObj.ITEM5.length === 0
        ) {
          this.isLoading = true;
          this.detailInfo = {};
          var chart1 = {
            categoryData: [],
            seriesData: [],
          };
          this.detailInfo.chart1 = chart1;
          this.initEchart();
          this.isLoading = false;
        } else {
          //this.getData();
        }
      }
      if(reload){
        this.getData();
      }
    },
    getData() {
      this.isLoading = true;
      this.detailInfo = {};
      let variableNames = [];
      let data = {
        assets: this.searchObj.ITEM,
        variableNames: [...this.searchObj.ITEM3, ...this.searchObj.ITEM5],
        type: 0,
      };
      Object.assign(data, this.transformSearch());
      //console.log(data);
      if (
        this.searchObj.ITEM === "" ||
        (this.searchObj.ITEM3.length === 0 && this.searchObj.ITEM5.length === 0)
      ) {
        this.isLoading = true;
        this.detailInfo = {};
        var chart1 = {
          categoryData: [],
          seriesData: [],
        };
        this.detailInfo.chart1 = chart1;
        this.initEchart();
        this.isLoading = false;
        return;
      }
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/energy/storage/operatingcurve",
        method: "post",
        data,
      }).then((res) => {
        var chart1 = {
          categoryData: res.data.data.categoryData,
          seriesData: res.data.data.seriesData,
        };
        this.detailInfo.chart1 = chart1;
        let newarr = [];
        if(this.detailInfo.chart1.seriesData==null){
          this.detailInfo.chart1.seriesData=[]
        }
        this.detailInfo.chart1.seriesData.forEach((item) => {
          item.values.forEach((val) => {
            newarr.push(val);
          });
        });
        if (this.getNull(newarr) == newarr.length) {
          this.gridLeft = [0, 0, 0, 40];
          this.gridright = [0, 40, 0, 0];
        } else {
          this.gridLeft = [0, 0, 0, 0];
          this.gridright = [0, 0, 0, 0];
        }
        this.initEchart();
        this.isLoading = false;
      });
    },
    getNull(newarr) {
      return newarr.reduce((total, currentValue) => {
        if (
          currentValue === null ||
          JSON.parse(currentValue) === null ||
          typeof currentValue == "Null"
        )
          return total + 1;
        return total;
      }, 0);
    },
    //每月报警预警数
    getData0() {
      this.isLoading = true;
      this.detailInfo = {};
      var chart1 = {
        categoryData: [
          "8:01",
          "8:02",
          "8:03",
          "8:04",
          "8:05",
          "8:06",
          "8:07",
          "8:08",
          "8:09",
          "8:10",
          "8:11",
          "8:12",
        ],
        seriesData: [
          {
            name: "A相",
            value: [, 280, 280, 290, 290, 450, 250, 270, 300, 330, 320, 310],
          },
          {
            name: "B相",
            value: [, 370, 370, 280, 280, 350, 350, 270, 300, 300, 255, 320],
          },
          {
            name: "C相",
            value: [, 420, 420, 320, 320, 350, 350, 370, 260, 300, 240, 330],
          },
        ],
      };
      this.detailInfo.chart1 = chart1;
      this.initEchart();
      this.isLoading = false;
    },
    initEchart() {
      var { categoryData, seriesData } = this.detailInfo.chart1;
      var series = [];
      //console.log(this.searchObj);
      seriesData.forEach((item) => {
        let obj = {};
        if (
          this.searchObj.ITEM5.findIndex((str) => {
            return item.variableName === str;
          }) !== -1
        ) {
          obj = {
            type: "line",
            name: item.name,
            data: item.values,
            animationDurationUpdate: 2000,
            animationEasingUpdate: "quinticInOut",
            smooth: false,
            emphasis: {
              focus: "series",
            },
            // showSymbol: false,
            // barGap:0,
            // barWidth: 10,
            yAxisIndex: 1,
          };
        } else {
          obj = {
            type: "line",
            name: item.name,
            data: item.values,
            animationDurationUpdate: 2000,
            animationEasingUpdate: "quinticInOut",
            smooth: false,
            emphasis: {
              focus: "series",
            },
            // showSymbol: false,
            // barGap:0,
            // barWidth: 10,
          };
        }

        series.push(obj);
      });
      var option = {
        animation: true,
        layoutAnimation: false,
        grid: {
          left: "2%",
          right: "2%",
          bottom: "7%",
          top: "15%",
          containLabel: true,
        },
        legend: {
          right: 0,
          top: "3%",
          icon: "rect", //图例形状
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#1d2129",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
            height: 20,
            bottom: 0,
          },
          {
            start: 0,
            end: 100,
            height: 20,
            bottom: 10,
          },
        ],
        xAxis: {
          type: "category",
          name: "",
          data: categoryData,
          boundaryGap: true,
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 14,
          },
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
        },
        yAxis: [
          {
            type: "value",
            name: this.searchObj.ITEM3.length === 0 ? "" : this.dwList[0],
            axisTick: {
              show: false,
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#9f9f9f",
              fontSize: 14,
              padding: this.gridLeft,
            },
            axisLine: {
              lineStyle: {
                color: "#1f1f1f",
              },
            },
            axisLabel: {
              // fontFamily: 'ABBvoice_WCNSG_Rg',
              color: "#696969",
              fontSize: 14,
            },
            splitLine: {
              show: true,
              lineStyle: {
                // type: "dashed",
                color: "#ebebee",
              },
            },
            // 控制数据参数最大值和最小值
            // interval: 50,
            // max: 100
          },
          {
            type: "value",
            name: this.searchObj.ITEM5.length === 0 ? "" : this.dwList[1],
            axisTick: {
              show: false,
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#9f9f9f",
              fontSize: 14,
              padding: this.gridright,
            },
            axisLine: {
              lineStyle: {
                color: "#1f1f1f",
              },
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#696969",
              fontSize: 14,
            },
            splitLine: {
              show: true,
              lineStyle: {
                // type: "dashed",
                color: "#ebebee",
              },
            },
            // 控制数据参数最大值和最小值
            // interval: 50,
            // max: 100
          },
        ],
        // 控住柱状图样式
        series: series,
        color: this.colorList,
      };

      this.updateChart("chart1", "chartOption1", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },
  mounted() {
    let lang = sessionStorage.getItem("locale") || 'CH'
    
      if(lang=='EN'){
        this.itemList2 = [
        {
          code: "1",
          name: "current",
        },
        {
          code: "2",
          name: "voltage",
        },
        {
          code: "3",
          name: "power",
        },
        {
          code: "4",
          name: "Power factor",
        },
        {
          code: "5",
          name: "Remperature",
        },
      ]
        
      }
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getList();
    }
  },
};
</script>

<style lang="less" scoped>
.chuneng6 {
  .card-content1 {
    div {
      padding-bottom: 6px;
    }
  }
  .card-content2 {
    flex: 1;
    height: 100%;
    div {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
